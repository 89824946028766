import React from 'react';

// img
// import closeModalImg from "../../../assets/img/closeModal.svg";

// 
// icon
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// component
import Modal from 'react-bootstrap/Modal';


function AddModal(props) {
    const {
        title,
        content,
        modal = false,
        modalSize = "sm", // ["xlg", "lg", "sm"]
        closeModal = () => { },
        closeOutSideClick = true,
        hasFooter = true
    } = props;

    return (
        <Modal
            show={modal}
            scrollable={true}
            className={`modalBaseStyle ${modalSize}`}
            onHide={closeOutSideClick ? closeModal : () => { }}
        >
            {/* /* --------------------------------- header --------------------------------- */}
            <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-sm">
                    <div>
                        <div className='closeModalImg pointer text-white' onClick={closeModal}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                        <div className='modalHeaderTitler'>
                            {title}
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={`modalContentHolder ${hasFooter ? "" : "noneFooter"}`}>
                    {props.children ?? content}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AddModal