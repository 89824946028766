import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

function CustomBtn(props) {
    const { 
        content, 
        img, 
        color, 
        fontCustomBtn, 
        onClick = () => {}, 
        showSpin = false
    } = props;

  return (
    <button onClick={onClick} style={{backgroundColor: color}} className={`px-4 py-2 ${fontCustomBtn} border-0 rounded`}>
        <span className='d-flex align-items-center'>
            {
                showSpin 
                  ?
                    <Spin indicator={ <LoadingOutlined style={{fontSize: 24, color: "#353d60", marginLeft: '10px'}} spin/> }/>
                  :
                    <>
                      <span className={`${img && "ms-2"}`}> { content } </span>
                      { img && <img src={img} alt="" /> }
                    </>
            }
          </span>
    </button>
  )
}

export default CustomBtn
