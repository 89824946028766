import React, { useEffect, useRef } from 'react'

function FloatInput(props) {
    const { 
        labelContent = null, 
        onChange = (e) => {}, 
        value,
        
        labelBackground = "#1E2235", 
        labelColor = "#ffffff85", 
        inputBackground = "#1D2236", 
        readOnly = false,
        disabled = false,
        className = "", 
        onKeyUp = (e) => {},
        onClick = (e) => {},
        updateRef = (ref) => {},
        inputMode="text",
        type="text",
        autoFocus = false,
    } = props;

    const ref = useRef(null);

    useEffect(() => {
        if(ref.current){
            updateRef(ref)
        }
    }, [ref])

    return (
        <div className="form-floating customFloatInput">
            <input
                autoFocus={autoFocus}
                ref={ref}
                value={value}
                onChange={onChange}
                type={type}
                autoComplete='off'
                className={`form-control ${className}`}
                placeholder="a"
                style={{ backgroundColor: inputBackground }}
                readOnly={readOnly}
                disabled={disabled}
                onKeyUp={onKeyUp}
                onClick={onClick}
                inputMode={`${inputMode}`}
            />
            <label style={{ color: labelColor, backgroundColor: labelBackground }}>
                {labelContent}
            </label>
        </div>
    )
}

export default FloatInput
