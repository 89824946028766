import { DataService } from "corePanel/api/dataService";

export const checkIsLogin_API = async () => {
    try {
        let response = await DataService.get(`/user`);
        return response.data.result;
    }
    catch (err) {
        throw err;
    }
}

export const login_API = async (body) => {
    try {
        let response = await DataService.post(`/user/login`, body);
        return response.data.result;
    }
    catch (err) {
        throw err;
    }
}

export const logout_API = async () => {
    alert("logout not working")
}