import axios from 'axios';
import { getItem, removeItem } from './localStorageControl';
import { API } from './api'
import { myToast } from '../helpers/myToast.helper';
import apiErrorResponse from './apiErrorResponse';

const API_ENDPOINT = API;

const authHeader = (token) => ({
    Authorization: `Bearer ${token ? token : getItem('token')}`,
    'content-type': 'application/json',
});

const client = axios.create({
    baseURL: API_ENDPOINT,
})

class DataService {
    static createQuery = (filters = {}) => {
        return Object.keys(filters).map(key => (`${key}=${filters[key]}`)).join('&')
    }

    static async get(url = '', token = '', signal, showError = true) {
        try {
            return await client({
                method: 'GET',
                url,
                headers: authHeader(token),
                signal
            });
        }
        catch (err) {
            if (showError) apiErrorResponse(err);
            throw err;
        }
    }

    static async post(path = '', data = {}, optionalHeader = {}, signal, showError = true) {
        try {
            let headers = { ...authHeader(), ...optionalHeader }
            return await client({
                method: 'POST',
                url: path,
                data,
                signal,
                headers,
            });
        }
        catch (err) {
            if (showError) apiErrorResponse(err);
            throw err;
        }
    }

    static async patch(path = '', data = {}, optionalHeader = {}, signal, showError = true) {

        try {
            return await client({
                method: 'PATCH',
                url: path,
                data,
                signal,
                headers: { ...authHeader(), ...optionalHeader },
            });
        }
        catch (err) {
            if (showError) apiErrorResponse(err);
            throw err;
        }
    }

    static async put(path = '', data = {}, optionalHeader = {}, signal, showError = true) {
        try {
            return await client({
                method: 'PUT',
                url: path,
                data,
                signal,
                headers: { ...authHeader(), ...optionalHeader },
            });
        }
        catch (err) {
            if (showError) apiErrorResponse(err);
            throw err;
        }
    }

    static async delete(path = '', data = {}, optionalHeader = {}, signal, showError = true) {
        try {
            return await client({
                method: 'DELETE',
                url: path,
                data,
                signal,
                headers: { ...authHeader(), ...optionalHeader },
            });
        }
        catch (err) {
            if (showError) apiErrorResponse(err);
            throw err;
        }
    }

}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use(config => {
    // do something before executing the request
    // For example tag along the bearer access token to request header or set a cookie
    const requestConfig = config;
    const { headers } = config;

    requestConfig.headers = { ...headers };

    return requestConfig;
});

// client.interceptors.response.use(
//     response => response,
//     error => {
//         /**
//          * Do something in case the response returns an error code [3**, 4**, 5**] etc
//          * For example, on token expiration retrieve a new access token, retry a failed request etc
//          */
//         return Promise.reject(error);
//     },
// );

export { DataService };