import React, { useState } from 'react';

// components
import Offcanvas from 'react-bootstrap/Offcanvas';
import SidebarItems from './SidebarItems/SidebarItems';

const Sidebar = props => {
    /* ---------------------------------- props ---------------------------------- */
    const { 
        sidebarItems, 
        sidebarFooter,
        clickOnSideBarItems,
        logoImg,
        offCanvasState,
        closeOffCanvas = () => {},
    } = props;

    /* --------------------------------- states --------------------------------- */
    const directionMode = localStorage.getItem("directionMode");

    /* -------------------------------- functions ------------------------------- */

    const clickHnadler = (item, type) => {
        if(type === "mobile") {
            closeOffCanvas();
        }
        clickOnSideBarItems(item);
    }

    return (
        <>
            <div>
                {/* --------------------------------- mobile --------------------------------- */}
                <Offcanvas 
                    placement={directionMode == "rtl" || !directionMode ? "start" : "start"} 
                    show={offCanvasState} 
                    onHide={closeOffCanvas} 
                    className="sidebarBg_mobile" 
                >
                    <Offcanvas.Header closeButton />
                    <Offcanvas.Body>
                        <SidebarItems 
                            initialState={sidebarItems}
                            sidebarFooter={sidebarFooter} 
                            clickOnSideBarItems={(item) => clickHnadler(item, "mobile")}
                            hasLogoInSidebar={true}
                            logoImg={logoImg}
                            
                        />
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
            {/* --------------------------------- desktop --------------------------------- */}
            <div className={`sidebarBg sidebar_dekstop flex-column`}>
                <div className={"sidebar_dekstop_items"}>
                    <SidebarItems 
                        initialState={sidebarItems}
                        sidebarFooter={sidebarFooter} 
                        clickOnSideBarItems={(item) => clickHnadler(item, "desktop")}
                    />
                </div>
            </div>
        </>
    )
}

export default Sidebar;