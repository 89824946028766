import React, { useState } from 'react';

// components
import { NavLink, useLocation } from "react-router-dom";

// redux
import ItemsWithChild from './ItemsWithChild';
import ItemsWithNoChild from './ItemsWithNoChild';

function SidebarItemsFooter(props) {

    const location = useLocation();

    /* ---------------------------------- props --------------------------------- */

    const { selected, clickOnSideBarItems, sidebarFooter } = props;


    return (

        <div>

            {
                sidebarFooter.map((item, index) =>
                    item.child && item.child.length
                    ?
                        <ItemsWithChild
                            key={`ItemsWithChild_${index}`}
                            sidebarItem={item} 
                            clickOnSideBarItems={clickOnSideBarItems} 
                        />
                    :
                        <ItemsWithNoChild 
                            key={`ItemsWithNoChild_${index}`}
                            sidebarItem={item} 
                            clickOnSideBarItems={clickOnSideBarItems} 
                        />
                )
            }
        </div>

    )
}

export default SidebarItemsFooter