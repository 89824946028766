import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

// components
import ItemsWithChild from './ItemsWithChild';
import ItemsWithNoChild from './ItemsWithNoChild';
import SidebarItemsFooter from './SidebarItemsFooter';


const SidebarItems = props => {

    /* ---------------------------------- props --------------------------------- */
    const { 
        initialState, 
        sidebarFooter, 
        clickOnSideBarItems, 
        hasLogoInSidebar,
        logoImg
    } = props;

    /* --------------------------------- states --------------------------------- */
    const [selected, setSelected] = useState(null);

    /* -------------------------------- functions ------------------------------- */
    const getBackSelctedHandler = event => {
        setSelected(event);
    }

    /* --------------------------------- effects -------------------------------- */
    

    useEffect(() => {
        if (selected) {
            // getBackClose();
        }
    }, [selected]);

    return (
        <>
            <div className={`d-flex flex-column sidebarHeight`}>
                {
                    hasLogoInSidebar
                    &&
                    <NavLink to="/" className='text-white d-flex logoHolder'>
                        <img src={logoImg} />
                    </NavLink>
                }
                <div className={`d-flex flex-column justify-content-between sidebarHeight sidebar_dekstop_items`}>
                    <div className='d-flex flex-column justify-content-between'>

                        {
                            initialState && initialState.length
                            ?
                                initialState.map((item, index) =>
                                    item.child && item.child.length
                                    ?
                                        <ItemsWithChild
                                            key={`ItemsWithChild_${index}`}
                                            sidebarItem={item} 
                                            clickOnSideBarItems={clickOnSideBarItems} 
                                        />
                                    :
                                        <ItemsWithNoChild 
                                            key={`ItemsWithNoChild_${index}`}
                                            sidebarItem={item} 
                                            clickOnSideBarItems={clickOnSideBarItems} 
                                        />
                                )
                            :
                                // <Skeleton active />
                                ""
                        }
                    </div>
                    <SidebarItemsFooter 
                        selected={selected} 
                        getBackSelcted={getBackSelctedHandler} 
                        sidebarFooter={sidebarFooter}
                        clickOnSideBarItems={clickOnSideBarItems}
                    />
                </div>
            </div>
        </>
    );
}


export default SidebarItems;