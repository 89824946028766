import React, { useState } from 'react';

import logoImg from "../../assets/img/logo.png";

import FloatInput from 'corePanel/components/Inputs/FloatInput';
import NumberInput from 'corePanel/components/Inputs/NumberInput';
import FormButton from 'corePanel/components/Buttons/FormButton';
import { login_API } from '../../api/auth/auth.api';

function Login(props) {

    const { doAfterLogin } = props;

    const [state, setState] = useState({
        username: "",
        password: ""
    })
    const [showSpin, setShowSpin] = useState(false);

    const handleUpdateState = (key, value) => {
        setState(prev => ({ ...prev, [key]: value }))
    }

    const handleSubmitLogin = () => {
        if (validation()) {
            setShowSpin(true);
            sendData();
        }
    }

    const sendData = () => {
        login_API(state)
            .then(res => {
                localStorage.setItem("token", res.token);
                doAfterLogin();
            })
            .catch(err => {
                setShowSpin(false);
            })
    }

    const validation = () => {
        return state.username && state?.username?.trim().length > 1 && state.password && state.password.trim().length > 1
    }

    return (
        <div className="fromHolder">
            <div className={`form`}>
                <div className="d-flex flex-column align-items-center">
                    <div className="imgHolder">
                        <img src={logoImg} alt="logo" />
                    </div>
                </div>

                <div className="formMarginTop">
                    <FloatInput
                        labelContent={"نام کاربری"}
                        onChange={(e) => handleUpdateState("username", e.target.value)}
                    />
                    <div className="mt-4">
                        <FloatInput
                            labelContent={"رمز عبور"}
                            type={"password"}
                            onChange={(e) => handleUpdateState("password", e.target.value)}
                            onKeyUp={(e) => e.key === 'Enter' && handleSubmitLogin()}
                        />
                    </div>
                    <FormButton
                        variant="primary"
                        content="ورود"
                        onClick={handleSubmitLogin}
                        className={validation() ? "completeOpacity pointer mt-4" : "halfOpacity notPointer mt-4"}
                        showSpin={showSpin}
                    />
                </div>
            </div>
        </div>
    )
}

export default Login;