import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { createRouteUrl } from './routeHelper';

const Home = lazy(() => import('../pages/Home/Home'));
const Live_Page = lazy(() => import('../pages/Live/LiveList/Live_Page'));
const LiveDetail_Page = lazy(() => import('../pages/Live/LiveDetail/LiveDetail_Page'));
const Intermediary_Page = lazy(() => import('../pages/Intermediary/Intermediary_Page'));

const MainRoutes = () => {
    return (
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path={createRouteUrl('home')} element={<Home />} />
                    <Route path={createRouteUrl('liveList')} element={<Live_Page />} />
                    <Route path={createRouteUrl('liveDetail')} element={<LiveDetail_Page />} />

                    <Route path={createRouteUrl('intermediary')} element={<Intermediary_Page />} />
                </Routes>
            </Suspense>
    );
};

export default MainRoutes;
