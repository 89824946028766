import React from 'react'
import "../../css/loading.scss"

function Loading(props) {
    const {
        text = ""
    } = props;

    return (
        <div className='loadingHolder'>
            <div className='spinnerLoading'>
            </div>

            <div className="text">
                { text }
            </div>
        </div>
    )
}

export default Loading