import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const myToast = (text, type) => {
    const typeOfToast = {
        success: {
            position: "top-center",
            autoClose: 5000,
            theme: "dark",
            type: "success",
            rtl: true
        },
        error: {
            position: "top-center",
            autoClose: 5000,
            theme: "dark",
            type: "error",
            rtl: true
        },
        unsucess: {
            position: "top-center",
            autoClose: 5000,
            theme: "dark",
            type: "error",
            rtl: true
        },
        wait: {
            position: "top-center",
            autoClose: 1000,
            theme: "dark",
            type: "info",
            rtl: true
        }
    }

    toast(text, typeOfToast[type])
}