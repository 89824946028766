import React, { useState } from 'react';

// component
import CustomBtn from '../../../components/Buttons/CustomBtn';
import AddModal from "../../../components/Modal/AddModal";

// api
import { logout_API } from '../../../../api/auth/auth.api';

// redux
import { useDispatch } from 'react-redux';

function LogOutModal(props) {

    /* ---------------------------------- props --------------------------------- */
    const { 
        closeModal, 
        isShow,
        onLogoutClick = null,
    } = props;

    /* ---------------------------------- state --------------------------------- */
    const [showSpin, setShowSpin] = useState(false);

    /* -------------------------------- functions ------------------------------- */
    const logoutHandler = () => {
        if(onLogoutClick !== null && typeof onLogoutClick === "function"){
            setShowSpin(true);
            onLogoutClick().then(res => {
                setShowSpin(false);
            }).catch(err => {
                setShowSpin(false);
            })
        }
        else{
            setShowSpin(true);
            logout_API()
            .then(() => {
                localStorage.removeItem("shotToken");
                window.location.reload();
            })
        }
    };

    return (
        <AddModal 
            modal={isShow} 
            closeModal={closeModal} 
            title="خروج"
            content={
                <>
                    <div className="container pt-4">
                        <span className='exitText px-3'>
                            آیا از خروج خود اطمینان دارید؟
                        </span>
                    </div>
                    <div className='exitBtns'>
                        <div className='me-2' onClick={logoutHandler}>
                            <CustomBtn showSpin={showSpin} color="#2563eb" content="بله" fontCustomBtn="addInvoiceBtnFont"/>
                        </div>
                        <div onClick={closeModal}>
                            <CustomBtn showSpin={showSpin} color="#9d8a8a85" content="خیر" fontCustomBtn="addInvoiceBtnFont"/>
                        </div>
                    </div>
                </>
            }
        />
    )
}

export default LogOutModal