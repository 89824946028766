import React from 'react'
import { Button } from "react-bootstrap";

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export default function FormButton(props) {

    const { 
        variant, //secondary, success, warning, danger, info, light, dark, link, primary
        content = null, 
        className = "", 
        style={},
        onClick = () => {}, 
        showSpin = false 
    } = props;

    return (
        <Button
            type="button"
            variant={variant}
            className={`submitBtn ${className}`}
            onClick={onClick}
            style={style}
        >
            {
                showSpin 
                ? <Spin indicator={ <LoadingOutlined style={{fontSize: 24, color: "#353d60", marginLeft: '10px'}} spin/> }/>
                : content
            }
        </Button>
    )
}
