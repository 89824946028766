import { Skeleton } from 'antd';
import React, { useState, useEffect } from 'react'

// component
import { NavLink, useLocation } from "react-router-dom";

function ItemsWithNoChild(props) {

    const location = useLocation();

    /* ---------------------------------- props --------------------------------- */

    const { sidebarItem, clickOnSideBarItems } = props;

    /* -------------------------------- functions ------------------------------- */

    const clickHandler = () => {
        clickOnSideBarItems(sidebarItem);
    }

    return (
        <>
            <NavLink
                key={sidebarItem.id} 
                to={sidebarItem.to}
                className={`d-flex sidebarLinksNoChild sidebarLinks sidebarSubAccordion ${sidebarItem.to === location.pathname ? "sidebarLinksActive" : ""}`}
                onClick={()=> clickHandler(sidebarItem)}
            >
                <span className='me-3 sidebarIconHolder'>
                    { sidebarItem.icon }
                </span>
                <span className='sidebarItems_NoChild d-block w-100'>
                    {sidebarItem.name}
                </span>
            </NavLink>
        </>
    )
}

export default ItemsWithNoChild;
