import React from 'react';

// component
import FloatInput from '../Inputs/FloatInput';

// helper
import { formatNumber, getOnlyNumbers } from '../../helpers/convertNumbers';
import Num2persian from 'num2persian';

function NumberInput(props) {

    const { 
        labelContent,
        labelBackground,
        value,
        hasSeparator = false,
        translationInWords = false,
        unit = "تومان",
        onChange,
        className = "",
        onKeyUp = () => {},
        disabled = false,
    } = props;

    const handleOnChange = (e) => {
        let number = getOnlyNumbers(e.target.value);
        if(hasSeparator){
            onChange(parseFloat(number))
        }
        else{
            onChange(number)
        }
    }

    return (
        <div className='position-relative leftInput'>
            <FloatInput
                onChange={handleOnChange}
                labelBackground={labelBackground}   
                labelContent={labelContent}     
                value={hasSeparator ? formatNumber(value) : value}
                className={className}
                inputMode={"numeric"}
                onKeyUp={onKeyUp}
                disabled={disabled}
            />
            {
                unit &&
                <span className='inputUnit'>{unit}</span>    
            }
            {
                (translationInWords && value) &&
                <span className='num2Persian'>
                    {`${Num2persian(value)} ${unit}`}
                </span>
            }   
        </div>
    )
}

export default NumberInput;