const urls = {
    home: { url: '/' },

    liveList: { url: '/live' },
    liveDetail: { url: '/live/detail', params: [":liveId"] },

    intermediary: { url: '/intermediary' },
};


export const createRouteUrl = code => {
    if (urls.hasOwnProperty(code)) {
        let url = urls[code];

        let params = '';
        if (url.hasOwnProperty('params')) {
            params = '/' + url.params.join('/');
        }

        return url.url + params;
    } else return '#';
};

export const createUrl = (code, params = []) => {
    if (urls.hasOwnProperty(code)) {
        let url = urls[code];
        let paramUrl = '';

        if (params.length > 0) {
            paramUrl = '/' + params.join('/');
        }
        return url.url + paramUrl;
    } else return '#';
};
