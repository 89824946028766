import React from 'react';

import logoImg from "../assets/img/logo.png";

function Header() {
    return (
        <div className='d-flex align-items-center'>
            <div className='me-3 logoHeader'>
                <img src={logoImg} alt="" width="80%" />
            </div>
            <span>
                {/* hello header */}
            </span>
        </div>
    )
}

export default Header
