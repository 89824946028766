const { myToast } = require("../helpers/myToast.helper");



module.exports = (error) => {
    const { response } = error;
    if (response) {
        let defaultError = ["خطا"];
        let errors = [];

        if (response.status === 400) {
            defaultError = ["خطا"]
        }
        else if (response.status === 401) {
            defaultError = ["ابتدا وارد شوید"];
        }
        else if (response.status === 403) {
            defaultError = ["عدم دسترسی"];
        }
        else if (response.status === 404) {
            defaultError = ["محتوای مورد نظر یافت نشد"];
        }
        else if (response.status >= 500) {
            defaultError = ["خطای سیستمی"];
        }

        errors = response?.data?.message.filter(item => item.trim().length > 0).length > 0 ? response?.data?.message : defaultError;
        errors.forEach(err => myToast(err, "error"))
    }
}