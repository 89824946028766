import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

// core css
import "corePanel/css/bootstrap.rtl.min.css";
import "corePanel/css/globals.scss";
import "corePanel/css/core.scss";
import "corePanel/css/header.scss";
import "corePanel/css/sidebar.scss";
import "corePanel/css/input.scss";
import "corePanel/css/calendar.scss";
import "corePanel/css/table.scss";
import "corePanel/css/Auth.scss";

// app css
import "./assets/css/main.scss"


import { changeData, setPermissions } from './store/profileSlice';

// img
import logoImg from "./assets/img/logo.png";

// api
import { checkIsLogin_API } from './api/auth/auth.api';

// components
import UserSideBar from './router/sidebar/UserSidebar';
import Core from 'corePanel/Layout/Core';
import LogOutModal from 'corePanel/Layout/Sidebar/SidebarItems/LogOutModal';
import Header from './Layout/Header';
import MainRoutes from './router/mainRoutes';
import Login from './pages/Auth/Login';
import Loading from 'corePanel/components/Loading/Loading';


function App() {

    const dispatch = useDispatch();

    /* --------------------------------- states --------------------------------- */
    const [exitModal, setExitModal] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [sidebarList, setSidebarList] = useState([]);

    /* -------------------------------- functions ------------------------------- */
    const clickOnSideBarItems = (item) => {
        if (item.id === "exit") {
            setExitModal(true);
        }
    }

    const doLogout = () => {
        localStorage.removeItem("token");
        window.location.reload()
    }

    const checkLogin = () => {
        checkIsLogin_API()
            .then(res => {
                dispatch(setPermissions(res.permissions))
                setSidebarList(UserSideBar(res))
                setIsLoggedIn(true);
            })
            .catch(err => {
                setIsLoggedIn(false);
            })
    }

    const handleAfterLogin = () => {
        setIsLoggedIn(true);
        window.location.reload();
    }

    /* --------------------------------- effects -------------------------------- */
    useEffect(() => {
        checkLogin();
    }, [])

    if(isLoggedIn === null){
        return <Loading />
    }

    return (
        <>
            {
                isLoggedIn !== false
                    ?
                    <>
                        <LogOutModal 
                            isShow={exitModal} 
                            closeModal={() => setExitModal(false)} 
                            onLogoutClick={doLogout}
                        />
                        <Core
                            sidebarItems={sidebarList}
                            clickOnSideBarItems={clickOnSideBarItems}
                            logoImg={logoImg}
                            openMenuButtonIcon={<FontAwesomeIcon icon={faBars} />}
                            headerContent={<Header />}
                        >
                            <MainRoutes />
                        </Core>
                    </>
                    :
                    <Login doAfterLogin={handleAfterLogin} />
            }
        </>
    );
}

export default App;
