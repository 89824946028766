import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    profileData: null,
    permissions: []
}

export const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        changeData: (state, action) => {
            state.profileData = action.payload;
        },
        setPermissions: (state, action) => {
            state.permissions = action.payload;
        }
    }
})

export const {
    changeData,
    setPermissions
} = profileSlice.actions;
export default profileSlice.reducer;