import React, { useState, useEffect } from 'react'

// component
import Header from './Header/Header';
import SideBar from "./Sidebar/Sidebar";


function Core(props) {

    const { 
        sidebarItems = [],
        clickOnSideBarItems = () => {},
        logoImg = "",
        openMenuButtonIcon = () => {},
        headerContent = null,
    } = props;

    /* --------------------------------- states --------------------------------- */
    const [offCanvasState, setOffCanvasState] = useState(false);

    /* -------------------------------- functions ------------------------------- */
    const clickHnadler = (item, type) => {
        clickOnSideBarItems(item);
    }

    /* --------------------------------- effects -------------------------------- */


    return (
        <>
            <Header 
                openOffCanvas={() => setOffCanvasState(true)}
                openMenuButtonIcon={openMenuButtonIcon}
            >
                { headerContent }
            </Header>
            
            <SideBar 
                sidebarItems={sidebarItems.filter(item => !item.isFooter)}
                sidebarFooter={sidebarItems.filter(item => item.isFooter)}
                clickOnSideBarItems={clickHnadler}
                logoImg={logoImg}
                closeOffCanvas={() => setOffCanvasState(false)}
                offCanvasState={offCanvasState}
            />
            <div className='mainContent'>
                { props.children }
            </div>
        </>
    )
}

export default Core
