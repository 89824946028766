import React, { useId } from 'react';

// route
import { NavLink, useLocation } from "react-router-dom";

// component
import Accordion from 'react-bootstrap/Accordion';

// icon
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function ItemsWithChild(props) {

    const location = useLocation();

    /* ---------------------------------- props --------------------------------- */
    const { sidebarItem, clickOnSideBarItems } = props;

    /* -------------------------------- functions ------------------------------- */
    const clickHandler = child => {
        clickOnSideBarItems(child);
    }

    return (
        <>
            <Accordion
                className={`navbarSidebar`}
                id={sidebarItem.engName}
            >
                <Accordion.Item eventKey={useId()} className="sidebarItemAcc">
                    <Accordion.Header className="accordionBtn">
                        <span className='me-3'>
                            {sidebarItem.icon}
                        </span>
                        <span className="sidebarItemsAccordion d-flex justify-content-between pe-0">
                            <span>
                                {sidebarItem.name}
                            </span>
                            <span className='accordionSidebarIcon'>
                                <FontAwesomeIcon icon={faChevronDown} />
                            </span>
                        </span>
                    </Accordion.Header>
                    {
                        sidebarItem.child.map((child, index) =>
                            <Accordion.Body key={`sidebarItem_${index}`} className="sidebarBg_accordio">
                                <div className='sidebarSubAccordion' key={child.id}>
                                        <NavLink
                                            className={`d-flex sidebarLinks ${child.to === location.pathname ? "sidebarLinksActive" : ""}`}
                                            to={child.to}
                                            onClick={() => clickHandler(child)}
                                        >
                                            <span className='sidebarItems_eng'>
                                                {child.name}
                                            </span>
                                        </NavLink>
                                </div>
                            </Accordion.Body>
                        )
                    }
                </Accordion.Item>
            </Accordion>
        </>
    )
}

export default ItemsWithChild
