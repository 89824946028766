import React from 'react';


const Header = (props) => {

    const { 
        openOffCanvas,
        openMenuButtonIcon 
    } = props;

    return (
        <header className="header">
            <div className="container-fluid">
                <div className="d-flex justify-content-between align-items-center">
                    <div className='d-block d-xl-none me-3' onClick={openOffCanvas}>
                        {openMenuButtonIcon}
                    </div>
                    <div className='flex-grow-2'>
                        { props.children }
                    </div>
                </div>
            </div>
        </header>
    );
}


export default Header;