import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faStream } from '@fortawesome/free-solid-svg-icons';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';

function UserSideBar(user){

    let routes = [
        {
            name: "خانه",
            id: "1",
            icon: <FontAwesomeIcon icon={faHome} />,
            to: "/",
            permissions: []
        },
        {
            name: "Live",
            id: "2",
            icon: <FontAwesomeIcon icon={faStream} />,
            to: "/live",
            permissions: []
        },
        {
            name: "واسطه ها",
            id: "2",
            icon: <FontAwesomeIcon icon={faStream} />,
            to: "/intermediary",
            permissions: []
        },
        {
            name: "خروج",
            id: "exit",
            icon: <FontAwesomeIcon icon={faUserGroup} />,
            isFooter: true,
            permissions: [],
        },
    ]

    return routes
}

export default UserSideBar;